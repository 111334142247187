.main-layout .content {
  border: 0px solid pink
}

#default-layout {}

#main-layout .content {
  padding: 50px 0 0px;
}

#main-layout nav {
  padding: 0;
}