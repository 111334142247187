#admin-content {
  padding: 80px 0 200px;
}

#admin-content .content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  margin-bottom: 40px;

}

#admin-content .content-title h1 {
  flex: 1;
  text-align: left;
  padding: 0 15px 7px 0;
  margin-bottom: 0;
}


#admin-content .content-title:before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 69px;
  height: 3px;
  content: ' ';
  background-color: #ffde00;
}


#admin-content .section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

#admin-content .section-title h2 {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}