.modal-tree-edit {}

.modal-tree-edit .modal-dialog,
.modal-alignment_-edit .modal-dialog {
  width: 96% !important;
  max-width: 1200px !important;
  width: 96vw !important;

}

.modal-tree-edit .modal-content {


  min-height: 90vh !important;
}