#header-main-left {
  text-align: left;
  font-weight: bold;
}

#header-main-center {
  text-align: center;
  justify-content: center;
}

#header-main-right {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}



#header-main a:hover {
  text-decoration: underline;
}