body .card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: var(--green-14);

  padding: 4px var(--bs-card-spacer-x);


}

body .card-title h5 {
  font-size: 1.1rem !important;

}


.card-body {
  padding: 10px
}

.card-body h6 {
  margin: 0;
}