.ui-toolbar {}

.ui-toolbar ul {}

.ui-toolbar ul.icons li {
  width: 36px;
  height: 29px;
  text-align: center;
  border-top: 1px solid rgba(66, 66, 66, 0.3);
  border-right: 1px solid rgba(66, 66, 66, 0.3);
  border-bottom: 1px solid rgba(66, 66, 66, 0.3);
}

.ui-toolbar ul.icons li:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 1px solid rgba(66, 66, 66, 0.3);
}

.ui-toolbar ul.icons li:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ui-toolbar li.disabled>* {
  opacity: 0.3;
}

.ui-toolbar li:not(.disabled) {
  cursor: pointer;
}

.ui-toolbar li.selected-tool {
  background-color: rgba(66, 66, 66, 0.2);
}

.ui-toolbar li svg {
  position: relative;
  top: -2px
}

.ui-toolbar .dropdown-item.hover,
.ui-toolbar .dropdown-item:hover,
.ui-toolbar .dropdown-item.active,
.ui-toolbar .dropdown-item:active {
  color: #111;
  text-decoration: none;
  background-color: rgba(192, 200, 159, 0.1);
}

.ui-toolbar .dropdown-menu {
  transform: translate(-10px, 30px) !important;
  border-radius: 0 !important;
}