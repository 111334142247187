#MapContextMenu {}

.contextMenu {
  position: absolute;
  width: 180px;
  min-height: 150px;
  background-color: white;
  border-radius: 7px;
  z-index: 10000;
  top: 100px;
  overflow: hidden;

  background-color: #fafafa;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contextMenu .header {
  background-color: rgba(192, 200, 159, 0.4);
  font-size: 0.85rem;

}

.contextMenu .header .main {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 85%;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.contextMenu .header svg {
  flex: 0 0 24px;

}

.contextMenu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.contextMenu li {
  padding: 1px 5px;
  font-size: 0.89rem;
  border-bottom: 1px solid #dddddd;
}

.contextMenu li:hover {
  background-color: rgba(192, 200, 159, 0.2);
}

.contextMenu li:last-child {
  border: none;
}

#MapContextMenu {}

#MapContextMenu img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}