body .nav-tabs {
  border: none !important;
}

.nav-tabs .nav-item {}

.nav-tabs .nav-link {}

.nav-tabs .nav-link.active {}

.tab-content {
  padding: 0 0 15px 0;
  display: flex;
  flex-direction: column;
  /*
  border: 1px solid #dee2e6;
  border-top: none;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  */
}

.tab-pane {
  display: none;
  height: 100%;
  flex-grow: 1;

}

.tab-pane.active {
  display: flex !important;
  flex-direction: column;
}

body .nav-tabs .nav-link {
  color: var(--green-1);
  border: none !important
}


body .nav-tabs .nav-link.active {
  color: var(--green-1);
  background-color: var(--green-14);

}

body .nav-tabs-tab {
  border: var(--green-14) 1px solid;
  padding: 1rem 1rem 3rem 1rem;
  border-radius: 0rem;
  border-top-left-radius: 0;
  min-height: 350px;

}