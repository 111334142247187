#abstilent {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #bd809f;
  height: 14px;
  z-index: 10000;
}

#abstilent .progress-bar {
  height: 100%;
  background-color: #bd809f;
  width: 96%;
  left: 2%;
  position: absolute;

}

#abstilent .progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #d96680;
  border-radius: 0 !important;
}




#abstilent .milestone {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  border-left: 2px white solid;

}


#abstilent .week {
  opacity: 0.7;
  background-color: transparent;
  font-size: 0.6rem;
  border-left: 1px solid #eee;
  color: white;
}