.userSignInModal {}

.userSignInModal h1 {
  font-size: 1.2rem;
  margin: 0 0 015px;
  padding: 0;
}

.userSignInModal .form-group {
  margin-bottom: 1rem;
}