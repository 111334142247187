.uploader {}

.uploader .drop-zone {
  line-height: 1;
  text-align: center;
}

.uploader .large .inner {
  flex: 1;
  padding: 7px 25px;
  background-color: rgba(192, 200, 159, 0.1);
  border-color: #c0c89f;


}





.uploader .small {
  width: 180px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploader .btn-upload {
  background-color: transparent;
  border: none;
  font-size: 3rem;
  opacity: 0.7;
  transition: all 300ms;
  color: #c0c89f;
}

.uploader .btn-upload:hover {
  transform: scale(1.2);
  opacity: 1;
}

.uploader .alert .btn-close {
  position: absolute;
  top: 10px;
  right: 10px
}

.uploader-infos {
  line-height: 2;
}