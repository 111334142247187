#ProjectItemsToolbar {}

#ProjectItemsToolbar ul {}

#ProjectItemsToolbar li {
  width: 36px;
  height: 29px;
  text-align: center;
  border-top: 1px solid rgba(66, 66, 66, 0.3);
  border-right: 1px solid rgba(66, 66, 66, 0.3);
  border-bottom: 1px solid rgba(66, 66, 66, 0.3);
}





#ProjectItemsToolbar.mobile ul {
  position: absolute;
  z-index: 1000;
  background: white;
  top: calc(100% + 0);
  left: -0px;
}

#ProjectItemsToolbar.mobile ul li:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 1px solid rgba(66, 66, 66, 0.3);
}

#ProjectItemsToolbar.mobile ul li:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}




#ProjectItemsToolbar li.disabled>* {
  opacity: 0.3;
}

#ProjectItemsToolbar li.selected-tool,
#ProjectItemsToolbar span.selected-tool {
  background-color: rgba(66, 66, 66, 0.2);
}

#ProjectItemsToolbar li svg {
  position: relative;
  top: -2px
}