#ProjectLayersFilter dd {
  position: relative;
}

#ProjectLayersFilter dd .buttons {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: white;
  padding: 3px 7px 1px 7px;

  transform: translateX(101%);
  transition: transform 500ms;
}

#ProjectLayersFilter dd:hover .buttons {
  transform: translateX(0%);

}