header {
  padding: 0 25px;
  position: fixed;
  width: 100%;
  border-bottom: 2px solid #343333;
  background-color: white;
  z-index: 1000;
}

header .col {
  padding: 10px;
  display: flex;
  align-items: center;
}

header a {
  color: #333;
  text-decoration: none;
  display: inline-block;
  padding: 0 10px;
}

header .badge-wrapper {
  position: relative;

}

header .badge-wrapper .badge {
  position: absolute;
  top: 60%;
  left: 60%;
  padding: 2px 4px;
}

header .logo img {
  max-height: 70px;
  transition: all;
}

body.scrolled header .logo img,
.header-main .logo img {
  max-height: 40px;
  transition: all 700ms;
}

.header-main {
  padding: 0;
}