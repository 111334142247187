.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 15px;
  background-clip: padding-box;
}

.my-masonry-grid_column>div {
  margin-bottom: 30px;
}


#TreeFormDiags table {}

#TreeFormDiags table th {
  text-align: center;
}

#TreeFormDiags table tr.striped td {
  background-color: #f5f5f5;
}


#TreeFormDiags table.table-sm th,
#TreeFormDiags table.table-sm td {
  padding: 0.4rem 0.2rem;
}

#TreeFormDiags table.table-sm tr,
#TreeFormDiags table.table-sm th,
#TreeFormDiags table.table-sm td {
  border-right: none;
  border-top: none;
  border-bottom: none;
}

#TreeFormDiags table.table-sm th:first-child,
#TreeFormDiags table.table-sm td:first-child {
  border-left: none;
}

.indicator-wrapper {

  text-align: center;
}


#TreeFormFeatures {
  margin-top: 1px;
}



#TreeFormFeatures .card-identity {
  border-top-left-radius: 0;
  border-top: none;
}

#TreeFormPhysiology div.presence_reiterations,
#TreeFormPhysiology div.qualite_reiterations {
  padding-top: 1rem;
}


#TreeFormTasks {}

#TreeFormTasks .form-group {}

#TreeFormTasks .card {
  flex: 1 0 100%;

}

#TreeFormTasks .card {
  flex: 1 0 100%;

}

#TreeFormTasks .nextExamination {
  width: 100%;
}

#TreeFormTasks .nextExamination .options {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 1;
  flex-direction: row;
}

#TreeFormTasks .nextExamination .checkboxes-with-custom-input .option {
  flex: 0 0 120px;
  padding-right: 10px;

}

#TreeFormTasks .card-comments textarea {
  height: 140px;
}


@media screen and (min-width: 991px) {


  #TreeFormTasks .card-tree-interventions,
  #TreeFormTasks .card-site-interventions,
  #TreeFormTasks .card-comments {
    flex: 0 0 calc(66% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }

  #TreeFormTasks .card-tree-interventions .card-body,
  #TreeFormTasks .card-site-interventions .card-body {
    display: flex;
  }

  #TreeFormTasks .card-tree-interventions .form-group,
  #TreeFormTasks .card-site-interventions .form-group {
    flex: 0 0 50%;
  }

  #TreeFormTasks .card-remanents,
  #TreeFormTasks .card-maintain {
    flex: 0 0 calc(33% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }



  #TreeFormTasks .card-examinations {
    flex: 0 0 calc(100% - 40px);
    margin-left: 15px;
    margin-right: 15px;
  }

  #TreeFormTasks .card-examinations .card-body {
    display: flex;
  }


  #TreeFormTasks .card-examinations .form-group {

    flex: 0 0 33.333333%;
  }
}