:root {

  --bs-btn-color: #fff;
  --bs-btn-bg: #d3e2c1;
  --bs-btn-border-color: #d3e2c1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #7fa574;
  --bs-btn-hover-border-color: #7fa574;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c0c89f;
  --bs-btn-active-border-color: #7fa574;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d3e2c1;
  --bs-btn-disabled-border-color: #d3e2c1;
  --bs-dropdown-link-active-color: #d3e2c1 !important;
  --bs-dropdown-link-active-bg: #d3e2c1 !important;

  --green-1: #183829;
  /* Empire */
  --green-2: #42522b;
  /* Bouteille */
  --green-3: #2f4d29;
  /* Sapin */
  --green-4: #3d5e33;
  /* Fougère */
  --green-5: #607c41;
  /* Kaki */
  --green-6: #748042;
  /* Olive */
  --green-7: #3a8542;
  /* Irlandais */
  --green-8: #5fa95c;
  /* Émeraude */
  --green-9: #4ba571;
  /* Canard */
  --green-10: #7fa574;
  /* Sauge */
  --green-11: #bdcf61;
  /* Pomme */
  --green-12: #89b94c;
  /* Prairie */
  --green-13: #d3e2c1;
  /* Menthe */
  --green-14: #c0c89f;
  /* Amande */


}

.text-green-1 {
  color: var(--green-1) !important;
  /* Empire */
}

.text-green-2 {
  color: var(--green-2) !important;
  /* Bouteille */
}

.text-green-3 {
  color: var(--green-3) !important;
  /* Sapin */
}

.text-green-4 {
  color: var(--green-4) !important;
  /* Fougère */
}

.text-green-5 {
  color: var(--green-5) !important;
  /* Kaki */
}

.text-green-6 {
  color: var(--green-6) !important;
  /* Olive */
}

.text-green-7 {
  color: var(--green-7) !important;
  /* Irlandais */
}

.text-green-8 {
  color: var(--green-8) !important;
  /* Émeraude */
}

.text-green-9 {
  color: var(--green-9) !important;
  /* Canard */
}

.text-green-10 {
  color: var(--green-10) !important;
  /* Sauge */
}

.text-green-11 {
  color: var(--green-11) !important;
  /* Pomme */
}

.text-green-12 {
  color: var(--green-12) !important;
  /* Prairie */
}

.text-green-13 {
  color: var(--green-13) !important;
  /* Menthe */
}

.text-green-14 {
  color: var(--green-14) !important;
  /* Amande */
}

.bg-green-1 {
  background-color: var(--green-1) !important;
  /* Empire */
}

.bg-green-2 {
  background-color: var(--green-2) !important;
  /* Bouteille */
}

.bg-green-3 {
  background-color: var(--green-3) !important;
  /* Sapin */
}

.bg-green-4 {
  background-color: var(--green-4) !important;
  /* Fougère */
}

.bg-green-5 {
  background-color: var(--green-5) !important;
  /* Kaki */
}

.bg-green-6 {
  background-color: var(--green-6) !important;
  /* Olive */
}

.bg-green-7 {
  background-color: var(--green-7) !important;
  /* Irlandais */
}

.bg-green-8 {
  background-color: var(--green-8) !important;
  /* Émeraude */
}

.bg-green-9 {
  background-color: var(--green-9) !important;
  /* Canard */
}

.bg-green-10 {
  background-color: var(--green-10) !important;
  /* Sauge */
}

.bg-green-11 {
  background-color: var(--green-11) !important;
  /* Pomme */
}

.bg-green-12 {
  background-color: var(--green-12) !important;
  /* Prairie */
}

.bg-green-13 {
  background-color: var(--green-13) !important;
  /* Menthe */
}

.bg-green-14 {
  background-color: var(--green-14) !important;
  /* Amande */
}



.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #d3e2c1;
  --bs-btn-border-color: #d3e2c1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #7fa574;
  --bs-btn-hover-border-color: #7fa574;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c0c89f;
  --bs-btn-active-border-color: #7fa574;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d3e2c1;
  --bs-btn-disabled-border-color: #d3e2c1;
  --bs-dropdown-link-active-color: #d3e2c1;
  --bs-dropdown-link-active-bg: #d3e2c1;
}


.btn-outlinine-green-14 {
  border-color: var(--green-14) !important;
  color: var(--green-1) !important;
}

.btn-outlinine-green-14:hover {
  background-color: var(--green-14) !important;
  color: var(--green-1) !important;
}







.text-red {
  color: red !important;
}