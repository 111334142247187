#CameraCapture {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 10000;
  background: black;
}

#CameraCapture .fullscreen-video {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

#CameraCapture .controls {
  position: absolute;

  left: 15px;
  right: 15px;
  text-align: center;
}

#CameraCapture .controls-top {
  top: 25px;
}

#CameraCapture .controls-bottom {
  bottom: 25px;
}


#CameraCapture .controls-bottom .row {
  align-items: flex-end;
}

#CameraCapture .message {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
}

#CameraCapture span.pointer {
  background: black;
  border-radius: 25%;
  display: inline-block;
}

#CameraCapture span.pointer svg {
  position: relative;
  top: 0px;
}