/* ProjectTopbar */
#ProjectTopbar {
  /* position: absolute !important; *
  top: 0px;
  left: 0;
  right: 0;*/

  background-color: rgba(192, 200, 159, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px;
}

#ProjectTopbar .cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0px;
  padding: 0 5px;
  /* border-right: 1px solid rgba(192, 200, 159, 0.5); */
  height: 40px;
}

#ProjectTopbar h1 {
  font-size: 1rem;
  padding: 0;
  margin: 0;
}


/* ProjectSidebar */








#ProjectSidebarContainer {
  position: relative;
  padding-top: 30px;
  width: 180px;
  transition: width 700ms;
}

#ProjectSidebarContainer .collapse-toggler {
  position: absolute;
  top: 30px;
  right: 5px;
  transform: translateY(-50%);
  z-index: 420;
  background-color: #ffffff;
  transition: right 300ms;
  padding: 5px 5px 5px 5px;

}

#ProjectSidebar {
  max-width: 100%;
  overflow: hidden;
}

#ProjectSidebarContainer.collapsed {
  width: 0;
}

#ProjectSidebarContainer.collapsed .collapse-toggler {
  right: -40px;
}

#ProjectSidebarContainer.collapsed #ProjectSidebar {
  overflow: hidden;
}

#ProjectSidebar ul,
#ProjectSidebar dl {
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 3px;
}

#ProjectSidebar li,
#ProjectSidebar dt,
#ProjectSidebar dd {
  padding: 1px 5px;
  margin: 0;
  line-height: 1.7;
  white-space: nowrap;
  cursor: pointer;

}

#ProjectSidebar dt,
#ProjectSidebar dd {
  font-size: 0.76rem;
  border-bottom: 1px solid #dddddd;
}

#ProjectSidebar dd:last-child {
  border: none;
}

#ProjectSidebar dt {
  background-color: rgba(192, 200, 159, 0.4);
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#ProjectSidebar dt span {
  position: relative;
  display: inline-block;
}

#ProjectSidebar dt span svg {
  top: -2px;
  position: relative;
}




#ProjectSidebar dd span svg {
  top: -1px;
  position: relative;
}

#ProjectSidebar span.disabled {

  cursor: no-drop;
  opacity: 0.3;
}


#ProjectSidebar dl.layers {}

#ProjectSidebar dd.hidden {
  opacity: 0.5;
}

#ProjectSidebar dd.active {
  background-color: rgba(192, 200, 159, 0.2);
}