#home-main {}


#home-main .home-blocs {
  padding-top: 80px;
  width: 80%;
  max-width: 991px;
  margin: 0 auto
}

#home-main .home-blocs>div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#home-main .home-blocs a {
  display: block;
  width: 120px;
  background-color: #026E6E;
  color: white;
  text-decoration: none;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: all 500ms;
  border-radius: 10px;
  box-shadow: 5px 4px 13px 0px #737373;
}

#home-main .home-blocs a:hover {
  opacity: 1;
  transform: scale(1.05);
}