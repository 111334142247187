.adaptations-backdrop {
  z-index: 1055 !important;
  /* Place le backdrop au-dessus de la première modale */
}

.adaptations-modal {
  z-index: 1060 !important;
  /* Place la modale enfant au-dessus du backdrop */
}

/*
.adaptation-modal .modal-dialog {
  z-index: 1065 !important;
  
}
*/