.mediaViewer {
  /* Styles existants */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  color: white;
}

.mediaViewer span {
  display: inline-block;
}

.mediaViewer .controls {
  position: absolute;
  width: 100%;
  left: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
}

.mediaViewer .controls-top {
  top: 0;
}

.mediaViewer .controls-bottom {
  bottom: 0;
}

.closeButton {
  color: white;
  font-size: 24px;
  background: none;
  border: none;
}

.mediaContent {
  color: white;
  /* Styles existants */
  position: relative;
  width: 96%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mediaContent textarea {
  height: 100%;
  width: 100%;
}


.mediaContent textarea.form-control:focus {
  border-color: initial !important;
  background-color: white !important;
}