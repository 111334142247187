html,
body {
  font-size: 96%;
}

#content {
  padding-top: 120px;
  transition: all 700ms;
  padding-bottom: 200px;
}

body.scrolled #content {
  padding-top: 90px;
}

a,
a.default {
  color: #666;
  cursor: pointer;
}

a.default {
  text-decoration: none;
}

a.default:hover {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

*:focus {
  outline: 0;
  box-shadow: none !important;
  border-color: inherit !important;
}

h1 {
  font-size: calc(1rem + 1vw);
  margin-bottom: 2rem;
}

h2 {
  font-size: calc(0.8rem + 1vw);
  margin-bottom: 2rem;
}

h3 {
  font-size: calc(0.7rem + 1vw);
  margin-bottom: 2rem;
}

small,
.small {
  font-size: 96%;
}

.big {
  font-size: 110% !important;
}

.nowrap {
  white-space: nowrap;
}

table thead th {
  vertical-align: middle !important;
  background-color: #bbb;
  font-size: 0.8rem;
}

table thead.spacer,
table tr.spacer,
table thead.spacer th,
table thead.spacer td,
table tbody.spacer td,
table tr.spacer td {
  height: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
  border: 0px solid transparent !important;
  box-shadow: none !important;
}

.alert-dismissible .btn-close {
  padding: 8px 12px 0 0;
  font-size: 10px !important;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 96% !important;
    max-width: 96% !important;
  }

  .container {}
}

.border-light {
  border-color: #dee2e6 !important;
}

img {
  max-width: 100%;
}

.accordion {
  border: none !important;
}

.accordion,
.accordion * {
  box-shadow: none !important;
}

.accordion-item {
  border: none !important
}

.accordion-header {
  border: none !important;
}

.accordion-header button {
  border: none !important;
  background-color: #fafafa !important;
  padding: 0 0 5px 0 !important;
  color: inherit !important;
}

.accordion-body {
  margin: 0;
  padding: 0
}

.modal .modal-dialog {}

.modal .modal-content {}

.modal-title {
  color: #333;
}

.modal-lg {
  max-width: 800px !important;
  width: 96% !important;
}

.underline-on-hover {
  text-decoration: none;
  cursor: pointer;
}

.underline-on-hover:hover {
  text-decoration: underline;
}



.construc,
.construct {
  border: 1px dotted pink;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.fs-7 {
  font-size: 0.9rem !important;
}

.fs-8 {
  font-size: 0.8rem !important;
}

.fs-9 {
  font-size: 0.7rem !important;
}

table.table-sm th,
table.table-sm td {
  font-size: 0.8rem !important;
  padding: 0.5rem 0.5rem;
}


.w-66 {
  width: 66.666666666666%;
  flex-basis: 66.666666666666%;

}

.w-33 {
  width: 33.333333333333%;
  flex-basis: 33.333333333333%;
}


.leaflet-tooltip {

  padding: 0px;
  min-width: 20px;
  line-height: 1;
  text-align: center;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}


.accordion-header button {
  font-weight: bold;
  font-size: 0.9rem;
  padding: 5px 10px !important;
  color: var(--green-1);
  background-color: rgba(192, 200, 159, 0.3) !important;
}

.accordion-item .card {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: none !important;
}