header {
  z-index: 1002;
}

.project-map-inner {
  height: calc(100vh - 60px);
  position: relative;
  display: flex;
  flex-direction: column;
}

#ProjectMap .main {
  flex-grow: 1;
}

#ProjectMap .sidebar {
  min-width: 0px;
}

#ProjectMap .map {
  flex-grow: 1;
  position: relative;
  background-color: white;
}

#ProjectMap #mapContainer {
  position: absolute !important;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
}

/* leaflet */
#mapContainer .leaflet-grab {
  cursor: pointer;
}

.leaflet-tooltip {}

.leaflet-tooltip span {
  position: relative;
  display: block;
  padding: 1px 2px;
  line-height: 14px;
  font-size: 12px;
}


/*
.leaflet-tooltip span.mecanique_A {
  background-color: #00b050;
}

.leaflet-tooltip span.mecanique_B {
  background-color: #92d050;
}

.leaflet-tooltip span.mecanique_C {
  background-color: #ffc000;
}

.leaflet-tooltip span.mecanique_D {
  background-color: #f47206;
}

.leaflet-tooltip span.mecanique_E {
  background-color: #f04e06;
}

.leaflet-tooltip span.mecanique_F {
  background-color: #ff0000;
}
  */