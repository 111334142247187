.form-group {
  border: 1px solid transparent;
  margin-bottom: 0.5rem;
}

button:disabled,
button[disabled],
button[disabled].btn,
button.btn:disabled {
  cursor: not-allowed !important;
}

input.form-control,
textarea.form-control,
select.form-control {
  border-color: #dee2e6 !important;
}

label.form-group-label {
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  font-weight: bold;

}


label.radio-label,
label.checkbox-label {
  font-size: 0.8rem;
  position: relative;
  height: 29px;
  display: flex;
  align-items: center;
}

label.radio-label input {
  margin-right: 4px;
}


label.checkbox-label input {
  margin-right: 4px;
}

span.required {
  display: inline-block;
  margin-left: 2px;
  font-size: 1rem
}

label[required]:after,
label.required:after,
span.required:after {
  content: '*';
  display: inline-block;
  margin-left: 5px;
  font-size: 110%;
  color: red;
}


.alert-form-error {
  white-space: pre-wrap !important;
  font-size: 0.8rem;
  padding: 7px 15px !important;
}

.form-control.error {
  border-color: red !important;
}

.form-control {
  transition: border-color 200ms, background-color 200ms;
}

.form-select:focus,
.form-control:focus {
  border-color: #c0c89f !important;
  background-color: rgba(192, 200, 159, 0.1) !important;
}

.carret::after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  transform: rotate(225deg);
  margin-left: 7px;
  position: relative;
  top: 1px;
  transform: all 200ms;
}

.carret.desc::after {
  transform: rotate(45deg);
  top: -2px;
}

table tfoot th,
table tfoot td {
  font-weight: normal !important;
}

button {
  border: none;
  border-radius: 5px;
}

body .btn-primary {
  color: #ffffff;
  background-color: #42522b;
  border-color: #183829;
}

body .btn-outline-primary {
  border-color: #183829;
  color: #183829;
}

body .btn-outline-primary:hover,
body .btn-outline-primary:active,
body .btn-primary:hover,
body .btn-primary:active {
  color: #ffffff;
  background-color: #405c34 !important;
  /* Darken background color */
  border-color: #183829 !important;
  /* Darken border color */
}


body .btn-outline-primary.disabled,
body .btn-outline-primary:disabled {

  border-color: #c0c89f;
  color: #c0c89f;
  opacity: 0.65;
  /* Reduce opacity */
}


body .btn-primary:focus,
body .btn-primary.focus {
  box-shadow: none !important
    /* Focus shadow */
}

body .btn-primary.disabled,
body .btn-primary:disabled {
  background-color: #c0c89f;
  border-color: #c0c89f;
  opacity: 0.65;
  /* Reduce opacity */
}

body .btn-primary:not(:disabled):not(.disabled):active,
body .btn-primary:not(:disabled):not(.disabled).active,
body .show>.btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #3d5e33;
  /* Further darken for active state */
  border-color: #3d5e33;
}

body .btn-primary:not(:disabled):not(.disabled):active:focus,
body .btn-primary:not(:disabled):not(.disabled).active:focus,
body .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}



.border-primary {
  border-color: #183829 !important;
}


.switcher.on .handler {
  background-color: #c0c89f !important;
}


.btn-light {
  background-color: rgba(192, 200, 159, 0.4) !important;
}


.input-group-sm button svg {
  width: 10px;
  margin-top: -5px;
  padding: 0;
}

.form-control-xs {
  height: calc(1.45rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-select-xs {
  height: calc(1.45rem + 2px);
  padding: 0.25rem 2rem 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  padding-right: 2rem;
}

.input-group-xs>.form-control,
.input-group-xs>.form-select,
.input-group-xs>.input-group-text {
  height: calc(1.45rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-xs button {
  height: 24px;
  width: 24px;
  padding: 0;

}

.input-group-xs button svg {
  width: 10px;
  height: 10px;
  margin-top: -4px;
}