#DrawingCanvas {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#DrawingCanvas .toolbar {

  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 2px 10px;
  z-index: 1200;
  background-color: #ededed;
}

#DrawingCanvas .tool {
  cursor: pointer;
  font-size: 20px;
  color: #333;
  /* Taille des icônes */
}




#DrawingCanvas .thickness {
  display: flex;

  align-items: center;
  flex-direction: column;

}

#DrawingCanvas span.tool {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#DrawingCanvas .thickness .tool:nth-child(1) {
  font-size: 5px;
  margin: 0 2px;
}

#DrawingCanvas .thickness .tool:nth-child(2) {
  font-size: 7.5px;
}

#DrawingCanvas .thickness .tool:nth-child(3) {
  font-size: 10px;
}


#DrawingCanvas .tool.selected {
  box-shadow: inset 0 0 3px #666;
  /* Exemple d'effet inset */
  border-radius: 4px;
  /* Arrondit les coins si nécessaire */
}

#DrawingCanvas .color-picker {
  cursor: pointer;
  width: 28px;
  border: none;
}

span.divider {
  height: 1px;
  width: 100%;
  border: #aaa 1px dotted;
  display: block;
}

#DrawingCanvas img,
#DrawingCanvas #canvas-over {
  position: absolute;
  left: 0;
  top: 0;
  border: 0px solid pink;

}