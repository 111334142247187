.autocomplete-input {
  position: relative;
  color: #111;
  flex: 1;
}

.autocomplete-input .autocomplete-container {
  position: absolute;
  top: 38px;
  left: 0;
  width: 100%;
  max-height: 200px;
  z-index: 9999;
  overflow-y: auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.autocomplete-input .suggestion {
  padding: 5px 6px;
  font-size: 0.8rem;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid white;
  overflow: hidden;
  display: block;
}

.autocomplete-input .suggestion:hover,
.autocomplete-input .suggestion.selected {
  background-color: rgba(192, 200, 159, 0.2) !important;
}